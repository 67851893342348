import api from '@services/api'

export default {
  create(axios, params, payload, config) {
    return api.post(axios, '/account/password', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/account/password', params, payload, config)
  },
}
