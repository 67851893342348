<template lang="pug">
  section.l-section
    .l-section-h
      b-card.card-transparent
        .pb-5.text-center
          .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
          .h4.m-0.mt-2.text-uppercase.text-white
            | {{ $wc.conf.base.name }}

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        b-form.form-wc.form-password-edit.mt-4(
          @submit.stop.prevent='formSubmit'
          @input.stop.prevent='formChange'
          @reset.stop.prevent='formReset')

          transition(name='fade')
            div(v-if='formShow')
              b-form-group#form-account-password.form-input-icon(
                :label="$t('session.placeholders.password')"
                label-for='account-password'
                label-sr-only)
                i.input-icon.text-primary.fal.fa-fw.fa-lock
                b-form-input#account-password.form-input-first.is-required(
                  name='password'
                  type='password'
                  autocomplete='password'
                  :placeholder="$t('session.placeholders.password')"
                  :state='formValidationState($v.form.data.attributes.password)'
                  @focus.native='formChange'
                  v-model='$v.form.data.attributes.password.$model'
                  aria-describedby='account-password-feedback'
                  trim)
                wc-forms-if#account-password-feedback(
                  extra-class='ml-feedback'
                  :attribute='$v.form.data.attributes.password'
                  :remote="formRemoteInvalidFeedback('password')"
                  :validators='{ required: {}, minLength: {}, maxLength: {} }')

              b-form-group#form-account-password_confirmation.form-input-icon(
                :label="$t('session.placeholders.password_confirmation')"
                label-for='account-password_confirmation'
                label-sr-only)
                i.input-icon.text-primary.fal.fa-fw.fa-lock
                b-form-input#account-password_confirmation.form-input-last.is-required(
                  name='password_confirmation'
                  type='password'
                  autocomplete='password-confirmation'
                  :placeholder="$t('session.placeholders.password_confirmation')"
                  :state='formValidationState($v.form.data.attributes.password_confirmation)'
                  @focus.native='formChange'
                  v-model='$v.form.data.attributes.password_confirmation.$model'
                  aria-describedby='password_confirmation-feedback'
                  trim)

                wc-forms-if#account-password_confirmation-feedback(
                  extra-class='ml-feedback'
                  :attribute='$v.form.data.attributes.password_confirmation'
                  :remote="formRemoteInvalidFeedback('password_confirmation')"
                  :validators='{ required: {}, sameAsPassword: {}, minLength: {}, maxLength: {} }')

              b-button.mt-4(
                type='submit'
                variant='secondary'
                :disabled="$v.form.data.$invalid"
                block)
                i.fal.fa-fw.fa-key
                |  {{ $t('session.set_password') }}

        .mt-5.help.text-center.small
          b-link(:to="{ name: 'login' }")
            span.text-body
              i.fal.fa-fw.fa-question-circle
              |  {{ $t('session.help.password') }}
            span.text-capitalize
              |  {{ $t('session.sign_in') }}

        loading(backgroundRadius='2rem' :active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import { Form } from '@common/form'
import apiPassword from '@services/api/account/password'
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'account-password-edit',
  mixins: [Form],
  computed: {
    apiParams() {
      return {
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  methods: {
    checkToken() {
      if (!this.$route.query.reset_password_token) {
        this.$router.replace(this.$route.query.redirect || { name: 'login' })
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            password: {
              required,
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password'),
            },
            password_confirmation: {
              required,
              sameAsPassword: sameAs(() => {
                return this.form.data.attributes.password
              }),
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password_confirmation'),
            },
            reset_password_token: {
              required,
            },
          },
        },
      },
    }
  },
  data() {
    return {
      skipFormFill: true,
      apiBase: apiPassword,
      apiModel: 'account',
      formBootstrapDefaults: {
        update: {
          data: {
            attributes: {
              password: '',
              password_confirmation: '',
              reset_password_token: this.$route.query.reset_password_token,
            },
          },
        },
      },
    }
  },
  created() {
    this.checkToken()
  },
}
</script>
